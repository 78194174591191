<template>
  <a-link
    v-if="isVisible"
    :to="tagUrl"
    open-in-new-tab
    class="ci-commit-tag-link"
  >
    {{ tagVersion }}
  </a-link>
</template>

<script>
import { ENVIRONMENT } from 'enums/environment'

export default {
  name: 'ACiCommitTag',
  computed: {
    isVisible() {
      const isDevelopment = process.env.NODE_ENV === 'development'
      const isStage = this.$env.ENVIRONMENT === ENVIRONMENT.STAGE

      return (isStage || isDevelopment) && this.$env.CI_COMMIT_TAG
    },
    tagVersion() {
      if (!this.$env.CI_COMMIT_TAG) return ''

      const isDevelopment = process.env.NODE_ENV === 'development'

      return isDevelopment
        ? this.$env.CI_COMMIT_TAG
        : this.$env.CI_COMMIT_TAG.slice(
            this.$env.CI_COMMIT_TAG.lastIndexOf('v') + 1
          )
    },
    tagUrl() {
      return `${this.$env.PROJECT_REPOSITORY_URL}/-/tags/${this.$env.CI_COMMIT_TAG}`
    }
  }
}
</script>

<style lang="scss" scoped>
.ci-commit-tag-link {
  position: absolute;
  top: calc(100% + 5px);
  left: 5px;
  padding: 5px;
  background: $c--white;
  border-radius: 5px;
  border: 1px solid $c--black;
}
</style>
